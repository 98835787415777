/* Magnific image popups */

$('.view-image-popup').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true
  }
});

$('.view-online-popup').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true
  }
});

$('.gallery-popup').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true
  }
});